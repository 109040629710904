// extracted by mini-css-extract-plugin
export var voicePage = "RecruitVoice-module--voicePage--1dSfO";
export var voicePage__main = "RecruitVoice-module--voicePage__main--14-Bx";
export var voicePage__post = "RecruitVoice-module--voicePage__post--24hiD";
export var voicePage__title = "RecruitVoice-module--voicePage__title--2RbvL";
export var voicePage__card = "RecruitVoice-module--voicePage__card--1ko9E";
export var voicePage__cardTop = "RecruitVoice-module--voicePage__cardTop--33gQV";
export var voicePage__cardBtm = "RecruitVoice-module--voicePage__cardBtm--39xls";
export var voicePage__cardBtmTitle = "RecruitVoice-module--voicePage__cardBtmTitle--2Wxh8";
export var voicePage__cardBtmText = "RecruitVoice-module--voicePage__cardBtmText--JGnFl";
export var voicePage__List = "RecruitVoice-module--voicePage__List--2WTce";
export var voicePage__question = "RecruitVoice-module--voicePage__question--2iKTP";
export var voicePage__questionSubject = "RecruitVoice-module--voicePage__questionSubject--2NfFq";
export var voicePage__outline = "RecruitVoice-module--voicePage__outline--3K6Gz";
export var voicePage__answer = "RecruitVoice-module--voicePage__answer--mQCr4";
export var voicePage__message = "RecruitVoice-module--voicePage__message--1CxQY";
export var voicePage__messageText = "RecruitVoice-module--voicePage__messageText--3JN5L";
export var voicePage__messageTextTitle = "RecruitVoice-module--voicePage__messageTextTitle--39UjC";
export var voicePage__messageTextMsg = "RecruitVoice-module--voicePage__messageTextMsg--2sd0c";
export var voicePage__messageImg = "RecruitVoice-module--voicePage__messageImg--2Gtil";
export var voicePage__toList = "RecruitVoice-module--voicePage__toList--1lSiT";