import React from "react"
import { graphql } from "gatsby"
import Seo from "../components/seo"
// import { Layout } from "../components/index"
import { StaticImage } from "gatsby-plugin-image"//ローカルから画像を持ってくる
import * as styles from "../styles/RecruitVoice.module.scss";
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { RecruitLayout,RecruitLowerHeaderBg,RecruitEntry } from "../components/Recruit/index";
import { LowerHeader } from "../components/LowerPages/index"

const RecruitVoice = ({ data }) => {
  const page = data.allWpRecruitVoice.edges[0].node
  const acf = page.RecruitVoiceField
  console.log(page)

  const breakpoints = useBreakpoint();
  const title1 = [
    { "id": 0, "text": "v" },
    { "id": 1, "text": "o" },
    { "id": 2, "text": "i" },
    { "id": 3, "text": "c" },
    { "id": 4, "text": "e" }
  ];
  return (
    <RecruitLayout className={`${styles.voicePage}`} pageName={`voice_page`} title={page.title}>
      <Seo
        title={page.title}
        description={page.excerpt}
      />
      <div className={`LowerHeadImg`}>
        {
          breakpoints.sm ?
            <StaticImage
              layout="fullWidth"
              src="../images/recruit/voice/voice_header-sp@2x.png"
              alt="news_header"
            />
            :
            <StaticImage
              layout="fullWidth"
              src="../images/recruit/voice/voice_header@2x.png"
              alt="news_header"
            />

        }
      </div>
      <div className={`LowerWrapper`}>
        <LowerHeader data={title1} title={`先輩たちの声`}>
          <RecruitLowerHeaderBg />
        </LowerHeader>
        <div className={`LowerContent`}>
          <div className={styles.voicePage__main}>
            <picture>
              <source srcSet={acf.mainvisual.localFile.childImageSharp.fluid.srcSetWebp} />
              <img src={acf.mainvisual.localFile.childImageSharp.fluid.src} alt="メイン" />
            </picture>
          </div>
          <div className={styles.voicePage__post}>
            <div className={styles.voicePage__title}>
              <h1>
                <small>point</small>
                <strong>{page.title}</strong>
              </h1>
              <div className={styles.voicePage__card}>
                <div className={styles.voicePage__cardTop}>
                  <span>{acf.department}</span>
                  <small>{acf.joindate}</small>
                </div>
                <div className={styles.voicePage__cardBtm}>
                  <div className={styles.voicePage__cardBtmTitle}>profile</div>
                  <div className={styles.voicePage__cardBtmText} dangerouslySetInnerHTML={{__html: acf.profile}} ></div>
                </div>
              </div>
            </div>
            <ol className={styles.voicePage__List}>
              <li>
                <div className={styles.voicePage__question}>
                  <div className={styles.voicePage__outline}>Q<small>01</small></div>
                  <div className={styles.voicePage__questionSubject}>現在の仕事内容について教えてください。</div>
                </div>
                <div className={styles.voicePage__answer}>{acf.question01}</div>
              </li>
              <li>
                <div className={styles.voicePage__question}>
                  <div className={styles.voicePage__outline}>Q<small>02</small></div>
                  <div className={styles.voicePage__questionSubject}>入協を決めた理由は?</div>
                </div>
                <div className={styles.voicePage__answer}>{acf.question02}</div>
              </li>
              <li>
                <div className={styles.voicePage__question}>
                  <div className={styles.voicePage__outline}>Q<small>03</small></div>
                  <div className={styles.voicePage__questionSubject}>仕事のやりがいや魅力は?</div>
                </div>
                <div className={styles.voicePage__answer}>{acf.question03}</div>
              </li>
              <li>
                <div className={styles.voicePage__question}>
                  <div className={styles.voicePage__outline}>Q<small>04</small></div>
                  <div className={styles.voicePage__questionSubject}>休日はどんな風に過ごしていますか?</div>
                </div>
                <div className={styles.voicePage__answer}>{acf.question04}</div>
              </li>
              <li>
                <div className={styles.voicePage__question}>
                  <div className={styles.voicePage__outline}>Q<small>05</small></div>
                  <div className={styles.voicePage__questionSubject}>仕事において、今後チャレンジしてみたいことは?</div>
                </div>
                <div className={styles.voicePage__answer}>{acf.question05}</div>
              </li>
              <li>
                <div className={styles.voicePage__question}>
                  <div className={styles.voicePage__outline}>Q<small>06</small></div>
                  <div className={styles.voicePage__questionSubject}>深川養鶏の最大の魅力、自慢できるポイントは?</div>
                </div>
                <div className={styles.voicePage__answer}>{acf.question06}</div>
              </li>
            </ol>
            <div className={styles.voicePage__message}>
              <div className={styles.voicePage__messageText} >
                <div className={styles.voicePage__messageTextTitle}>就活中の皆さんへメッセージ</div>
                <div className={styles.voicePage__messageTextMsg} dangerouslySetInnerHTML={{__html: acf.forYourMessage}}></div>
              </div>
              <div className={styles.voicePage__messageImg}>
                <picture>
                  <source srcSet={acf.messageImg.localFile.childImageSharp.fluid.srcSetWebp} />
                  <img src={acf.messageImg.localFile.childImageSharp.fluid.src} alt="message_img" />
                </picture>
              </div>
            </div>
          </div>{/* END post */}
          <div className={styles.voicePage__toList}>
            <a href="/voice/">
              先輩たちの声一覧
            </a>
          </div>
          <section>
            <RecruitEntry/>
          </section>
        </div>
      </div>
    </RecruitLayout>
  )
}
export default RecruitVoice

export const query = graphql`
 query($slug: String!) {
   site {
     siteMetadata {
       title
       siteUrl
     }
   }
  allWpRecruitVoice (filter: { slug: { eq: $slug } }){
    edges {
      node {
        title
        slug
        id
        link
        guid
        desiredSlug
        databaseId
        RecruitVoiceField {
          question06
          question05
          question04
          question03
          question02
          question01
          profile
          department
          fieldGroupName
          forYourMessage
          joindate
          mainvisual {
            localFile {
              childImageSharp {
                fluid {
                  src
                  srcSetWebp
                }
              }
            }
          }
          messageImg {
            localFile {
              childImageSharp {
                fluid {
                  src
                  srcSetWebp
                }
              }
            }
          }
        }
      }
    }
  }
 }
`